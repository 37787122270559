import axios from "axios";

export default {
    async getRapportini(userID: string) {
        const data = new FormData();
        data.append("limit", "150");
        data.append("offset", "0");
        data.append("where[]", `rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = ${userID})`);
        data.append("orderby", "rapportini_data");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/rapportini", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    /*  async getRapportini(userID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("offset", "0");
        data.append("where[]", `rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = ${userID})`);
        data.append("orderby", "rapportini_data");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/rapportini", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data);
        return response.data;
    }, */

    /**
     *
     * @param appuntamentoId
     * @returns rapportino creato da me per l'appuntamento in questione
     */
    async getRapportinoAppuntamento(appuntamentoId: string, userID: string) {
        const data = new FormData();
        data.append("where[]", `rapportini_appuntamento_id = ${appuntamentoId}`);
        data.append("where[]", `rapportini_id IN (SELECT rapportini_id FROM rel_rapportini_users WHERE users_id = ${userID})`);

        const response = await axios.post("rest/v1/search/rapportini", data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        return response.data;
    },

    async saveRapportino(formData) {
        const response = await axios.post("rest/v1/create/rapportini", formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response;
    },

    async editRapportino(formData, rapportino_id) {
        const response = await axios.post(`rest/v1/edit/rapportini/${rapportino_id}`, formData, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response.data.data);
        return response;
    },
};
